$(window).load(function () {
  //Preloader
  $('#status').delay(300).fadeOut();
  $('#preloader').delay(300).fadeOut('slow');
  $('body').delay(550).css({'overflow': 'visible'});
});

$(document).ready(function () {
  //animated logo
  $(".navbar-brand").hover(function () {
    $(this).toggleClass("animated shake");
  });

  //animated scroll_arrow
  //$(".img_scroll").hover(function () {
  //	$(this).toggleClass("animated infinite bounce");
  //});

  //MagnificPopup
  //$('.image-link').magnificPopup({type: 'image'});


  // OwlCarousel N1
  //$("#owl-demo").owlCarousel({
  //	autoPlay: 3000,
  //	items : 3,
  //	itemsDesktop : [1199,3],
  //	itemsDesktopSmall : [979,3]
  //});

  // OwlCarousel N2
  //$("#owl-demo-1").owlCarousel({
  //	  navigation : false, // Show next and prev buttons
  //	  slideSpeed : 300,
  //	  paginationSpeed : 400,
  //	  singleItem:true
  //});

  //SmothScroll
  $('a[href*=#]').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        $('html,body').animate({scrollTop: targetOffset}, 600);
        return false;
      }
    }
  });

  //Subscribe
  //new UIMorphingButton( document.querySelector( '.morph-button' ) );
  //// for demo purposes only
  //[].slice.call( document.querySelectorAll( 'form button' ) ).forEach( function( bttn ) {
  //	bttn.addEventListener( 'click', function( ev ) { ev.preventDefault(); } );
  //} );


  // TYPO3 check
  $('#typo3-check-form').submit(function () {
    var $form = $(this);
    var $buttons = $form.find('button').attr("disabled", true);
    var $loadingWrapper = $('.typo3-check-loading-wrapper').removeClass('hidden');
    var $resultOuterWrapper = $('.typo3-check-result-wrapper');
    var $resultSuccess = $resultOuterWrapper.find('.typo3-check-result-success').addClass('hidden');
    var $resultFailed = $resultOuterWrapper.find('.typo3-check-result-failed').addClass('hidden');
    var $resultWrapper = $('.typo3-check-result').addClass('hidden');
    $.ajax({
      type: 'POST',
      url: 'https://api.typo3-check.different.technology/v1/check',
      data: JSON.stringify({
        'url': $form.find('input[name="url"]').val()
      }),
      dataType: 'json'
    }).done(function (response) {
      $resultWrapper.html('').removeClass('hidden');
      var items = 0;
      var testSuccess = true;
      for (var checkName in response.results) {
        items++;
        var messages = '&#10004; passed';

        var resultClass = 'success';
        if (response.results[checkName].severity > 0) {
          resultClass = 'warning';
          testSuccess = false;
        }
        if (response.results[checkName].severity > 0.5) {
          resultClass = 'danger';
        }

        if (response.results[checkName].publicMessages.length !== 0) {
          messages = '<ul>';
          response.results[checkName].publicMessages.forEach(function (message) {
            messages += '<li>&#10005; ' + message + '</li>';
          });
          messages += '</ul>';
        }
        $resultWrapper.append('<div class="panel panel-' + resultClass + '">' +
          '<div class="panel-heading">' +
          '<h4 class="panel-title">' + checkName + '</h4>' +
          '</div>' +
          '<div class="panel-body">' + messages + '</div>' +
          '</div>');
      }
      if (items === 0) {
        $resultWrapper.append('<div class="panel panel-warning">' +
          '<div class="panel-heading">' +
          '<h4 class="panel-title">Test nicht möglich</h4>' +
          '</div>' +
          '<div class="panel-body">Es war leider nicht möglich Ihre TYPO3 Website zu testen. Ist die URL richtig?</div>' +
          '</div>');
      }
      if (testSuccess) {
        $resultSuccess.removeClass('hidden');
        $resultFailed.addClass('hidden');
      } else {
        $resultSuccess.addClass('hidden');
        $resultFailed.removeClass('hidden');
      }
    }).always(function () {
      $loadingWrapper.addClass('hidden');
      $buttons.removeAttr("disabled");
    });
    return false;
  });
});


jQuery(function ($) {
  $(document).ready(function () {
    var $navBar = $('.navbar-default');
    if ($navBar.length !== 0) {
      $navBar.stickUp();
    }
  });
});

document.addEventListener("DOMContentLoaded", function(event) {
  var contactLinks = document.getElementsByClassName("contact-link");
  var i;
  for (i = 0; i < contactLinks.length; i++) {
    contactLinks[i].onclick = function() {
      var domain = 'different.technology';
      var at = '@';
      window.location.href = "mailto:info" + at + domain;
      return false;
    };
  }
});
